<template>
  <admin>
    <page-header
      :back="{ to: { name: 'dealers-account-maintenance.dealers.index' }, text: trans('Dealers') }"
    >
      <template v-slot:title><span v-text="trans('Edit Dealer')"></span></template>
    </page-header>

    <div class="row">
      <div class="col-lg-9 col-12">
        <Details v-if="show" :details="details" />
      </div>
    </div>
  </admin>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Details from "./partials/forms/Details.vue";

export default {
  components: {
    Details,
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapGetters({
      details: "dealers/GET_DEALER",
    }),
  },
  methods: {
    ...mapActions({
      getDealer: "dealers/getDealer",
    }),
  },
  async mounted() {
    await this.getDealer(this.$route.params.id);
    this.show = true;
  },
};
</script>

<style></style>
